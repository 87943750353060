import React, { Component } from 'react';
import { connect } from "react-redux";
import userAccountApi from '../../api/user-account-api';
import defaultColumns from "./default-columns.json";
import { createUUID } from '../react-flow-pedigree/utils';
import CopySurveyModal from './survey-copy-modal';
import SurveySearch from './survey-search';
import DeleteSurveyModal from './survey-delete-modal';
import EditAddQuestionModal from './edit-add-question-modal';
import * as helper_search from "../../helpers/helper-search";

interface  ModalProps{
    searchFilter: any;
    dispatch: (action:any) => void;
    questionList: any;
    columns: any;
    openAddModal: boolean;
    closeAddModal: () => void;
    updateQuestionList: () => void;
  }

interface State{
  questionList: Array<any>;
  columns: Array<any>;
  searchText: string;
  showCopyEditModal: boolean;
  copyEditTitle: string;
  openAddQuestionModal: boolean;
  actionTaken: string;
  activeQuestion: any;


}

type Question = {
  name: string;
  organization: string;
  id: number
  translation_key: string;
}


class SurveyQuestionList extends Component<ModalProps, State> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
          questionList: [],
          columns: [],
          searchText: "",
          showCopyEditModal: false,
          copyEditTitle: "",
          openAddQuestionModal: false,
          actionTaken: "",
          activeQuestion: {}

        };

      this.openCopyModal = this.openCopyModal.bind(this);
      this.onClose = this.onClose.bind(this);
      this.onSaveQuestion = this.onSaveQuestion.bind(this);

    }
  componentWillUnmount(): void {
    helper_search.updateSearchText(this.props.dispatch, "");
  }


  openCopyModal(question: Question){
    this.setState({
      showCopyEditModal: true,
      copyEditTitle: "Copy Survey",
      actionTaken: "copy",
      activeQuestion: question
    })

  }

  openEditModal(question: Question){

    this.setState({
      showCopyEditModal: true,
      copyEditTitle: "Edit Survey",
      actionTaken: "edit",
      activeQuestion: question,

    })

  }

  onClose(){
    this.setState({
      showCopyEditModal: false,
      actionTaken: "",
    })
    if(this.props.openAddModal) this.props.closeAddModal()

  }

  onSaveQuestion(action: string, surveyQuestion: any, questionOptions: any){
    switch(action){
      case 'edit':
        this.editQuestion(surveyQuestion, questionOptions)
        break;
      default:
        this.saveNewQuestion(surveyQuestion, questionOptions)
        break;

    }
  }

  async editQuestion(surveyQuestion: any, questionOptions: any){

    try{
      let payload = {
        'surveyQuestion': surveyQuestion,
        'questionOptions': questionOptions
      }
      await userAccountApi.edit_survey_question(payload)

    }catch(err){
      console.dir(err)
    }finally{
      this.onClose()
      this.props.updateQuestionList()
    }
  }

  async saveNewQuestion(surveyQuestion: any, questionOptions: any){
    try{
      let payload = {
        'surveyQuestion': surveyQuestion,
        'questionOptions': questionOptions
      }
      await userAccountApi.create_survey_question(payload)
    }catch(err){
      console.dir(err)
    }finally{
      this.onClose()
      this.props.updateQuestionList()
    }
  }

  render() {
    return(
      <React.Fragment>
          <table id="questionsTable" className="table table-custom ">
              <thead>
                <tr >
                {this.props.columns.map((item: any , index: number)=> {
                    return <th key={index}>{item.label}</th>
                  })}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.props.questionList.map((question: any) => {
                  return (
                    <React.Fragment key={createUUID()}>
                      <tr>
                      {this.props.columns.map((item: any) => {
                          return (
                            <td key={createUUID()}>
                              {question[item.value] || ''}
                            </td>
                         );
                       })}
                     
                        <td className="middle">
                          <button style={{margin: '1px'}} className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.openEditModal(question)}><i className="fa fa-eye"></i>&nbsp;Edit</button>
                          <button style={{margin: '1px'}} className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.openCopyModal(question)}><i className="fa fa-eye"></i>&nbsp;Copy</button>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          
          {(this.state.showCopyEditModal || this.props.openAddModal)&&(
            <EditAddQuestionModal 
              modalTitle={this.props.openAddModal ? 'Add Question': this.state.copyEditTitle}
              onClose={this.onClose}
              onSave={(action, surveyQuestion, questionOptions)=> this.onSaveQuestion(action, surveyQuestion, questionOptions)}
              actionTaken={this.props.openAddModal ? 'add' : this.state.actionTaken}
              activeSurvey={this.props.openAddModal ? {} : this.state.activeQuestion}
            />
          )}

      </React.Fragment>

    );
  };
}


const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveyQuestionList);
