import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";

interface PanelProps {
    onCancel: () => void;
    isOpen: boolean;
    title?: string;
    message?: string;
    onOk: () => void;
}

const ModalConfirmPanelChange: React.FC<PanelProps> = ({onCancel, isOpen, title, message, onOk }) =>{

  const cancel = 'Cancel';
  const confirm = 'Continue'

  return(
    <div
        style={{display: isOpen ? "block" : "none" }}
        onClick={onCancel}
        className={"modal fade " + (isOpen ? "in" : "")}
        role="dialog"
    >
      <div
        onClick={e => e.stopPropagation()}
        className="modal-dialog modal-sm"
        role="document"
      >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={onCancel}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">
                {title ? title : "Delete Details"}
              </h4>
            </div>
            <div className="modal-body">
              <div className="modal-confirm">
                <p>
                  {message}
                </p>
                <br />
                  <div className="buttons">

                    
                    <button
                      style={{width: 'auto'}}
                      onClick={onCancel}
                      className="btn btn-light btn-sm"
                      aria-label="Close"
                    >
                      {cancel}
                    </button>
                    <button
                      style={{width: 'auto'}}
                      onClick={onOk}
                      className="btn btn-purple btn-sm"
                    >
                      {confirm}
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    );
};

export default ModalConfirmPanelChange;