export const TwinConnectorNodeName = "twin-connector-node";

export const PersonNodeWidth = 40;
export const PersonNodeHeight = 40;

export const ConnectorNodeWidth = 3;
export const ConnectorNodeHeight = 3;

// Zoom range from 0.2 - 2.2
export const MinZoom = 0.2;
export const MaxZoom = 2.2;
export const defaultViewport = { x: null, y: null, zoom: 1.0 };

export const multiSelectionKeyCode = () => {
  let multiSelectionKeyCode = 'Meta';
  let platform = 'unknown'
  if(navigator.userAgentData !== undefined){
    platform = navigator.userAgentData.platform
  }
  else{
    if(navigator.platform !== undefined){
      platform = navigator.platform
    }
  }
  if (platform.includes('Win')){
    multiSelectionKeyCode = '17'
  }
  return multiSelectionKeyCode
}

export const oneClickAddDropdowns = {
  ADD_CHILDREN: 'add_children',
  ADD_PARTNER: 'add_partner',
  ADD_SIBLING: 'add_sibling'
};

export const oneClickAddActionTypes = {
  SON: 'son',
  DAUGHTER: 'daughter',
  UNKNOWN: 'unknown',
  NO_CHILDREN: 'no_children',
  INFERTILITY: 'infertility',
  SAB: 'sab',
  MALE_FETAS: 'male_fetas',
  FEMALE_FETAS: 'female_fetas',
  PREGNANCY_UNKNOWN: 'pregnancy_unknown',
  MARRIED: 'married',
  SAME_SEX: 'same_sex',
  SEPARATED: 'separated',
  DIVORCED: 'divorced',
  OTHER: 'other',
  CONSANGUINEOUS: 'consanguineous',
  SIBLING_SON: 'sibling_son',
  SIBLING_DAUGHTER: 'sibling_daughter',
  SIBLING_UNKNOWN: 'sibling_unknown',
  SIBLING_TWIN_BROTHER: 'sibling_twin_brother',
  SIBLING_TWIN_SISTER: 'sibling_twin_sister',
  SIBLING_TWIN_UNKNOWN: 'sibling_twin_unknown',
  PARENTS: 'parents'
};

export const ApiNodeTypes = {
  NORMAL: 'normal',
  PERSON: 'Person',
  TOP: 'top',
  BOTTOM: 'bottom',
  SUBTEXT: 'subtext',
  DONOR_CONNECTOR: 'donor_connector',
};

export const ApiEdgeTypes = {
  TopToBottom: "TopToBottom",
  BottomToChild: "BottomToChild",
  PersonToTop: "PersonToTop",
  PersonToBottom: "PersonToBottom",
  ConnectorToConnector: "ConnectorToConnector"
}

export const ClientSideNodeTypes = {
  PERSON: 'person',
  ANCESTRY: 'ancestry'
};

export const ClientSideEdgeTypes = {
  BottomToTwinChild: "BottomToTwinChild",
};

export const snapGrid = [5, 5];

export const showPersonID = false;

export const PedigreeStylesheet = {
  reactflowWrapper: {
    flexDirection: 'column',
    display: 'flex'
  },
  personNode: {
    height: `${PersonNodeHeight}px`,
    width: `${PersonNodeWidth}px`
  },
  personNodeSelected: {
    stroke: '#66D1BA'
  },
  personNodeFemale: {
    strokeWidth: 2,
    fill: 'white',
    stroke: '#bdbdbd'
  },
  personNodeMale: {
    strokeWidth: 2,
    fill: 'white',
    stroke: '#bdbdbd'
  },
  personNodeUnknown: {
    strokeWidth: 2,
    fill: 'white',
    stroke: '#bdbdbd'
  },
  personNodeProband: {
    // fill: 'coral'
  },
  pregnancyNodeText: {
    fill: "black",
    fontWeight: 'bold'
  },
  connectorNode: {
    backgroundColor: '#777',
    border: '1px solid #777',
    height: `${ConnectorNodeHeight}px`,
    width: `${ConnectorNodeWidth}px`,
    padding: '0px',
    borderRadius: '40px'
  },
  donorConnectorNode: {
    backgroundColor: '#777',
    border: '1px solid #777',
    height: `${ConnectorNodeHeight}px`,
    width: `${ConnectorNodeWidth}px`,
    padding: '0px',
    borderRadius: '40px',
    visibility: 'hidden'
  },
  // subtextNode: {
  //   backgroundColor: 'inherit',
  //   color: '#000000',
  //   // border: '1px solid #222138',
  //   border: 'none',
  //   width: '110px',
  //   padding: '1px',
  //   fontSize: '10px',
  //   fontFamily: 'montserrat',
  //   wordWrap: 'break-word',
  //   marginLeft: '-35px',
  //   textAlign: 'center',
  //   lineHeight: '13px',
  //   whiteSpace: 'pre-wrap'
  // },
  subtextNode: {
    background: 'linear-gradient(90deg, #fafafa 50%, #fafafa 50%)',
    color: '#000',
    // border: '1px solid #222138',
    border: 'none',
    // adjustment here for subtext width
    maxWidth: '140px',
    padding: '1px',
    fontSize: '13px',
    fontFamily: 'Inter, sans-serif',
    wordWrap: 'break-word',
    marginLeft: '-50px',
    textAlign: 'center',
    lineHeight: '15px',
    whiteSpace: 'pre-wrap',
    display: 'table',
    position: 'relative',
  },
  edgeNode: {
    stroke: '#bdbdbd',
    strokeWidth: '2px'
  },
  ancestryNode: {
    fontFamily: 'Inter, sans-serif',
    color: '#333333',
    fontSize: '12px',
    wordWrap: 'break-word',
    lineHeight: '13px',
    whiteSpace: 'pre-wrap'
  },
  ancestryNodeLabel: {
    fontFamily: 'Inter, sans-serif',
    color: '#333333',
    fontSize: '10px',
    lineHeight: '10px',
  },
  oneClickDOMContainer: {
    position: 'absolute',
    display: 'flex',
    border: 'none'
  },
  oneClickAddSvg: {
    height: '14px',
    width: '14px'
  },
  oneClickAddSvgSymbol: {
    strokeWidth: 1,
    fill: 'white',
    stroke: '#bdbdbd'
  },
  oneClickAddSvgSymbolMale: {
    x: 1,
    y: 1,
    width: '12px',
    height: '12px'
  },
  oneClickAddSvgSymbolFemale: {
    cx: 7,
    cy: 7,
    r: 6
  },
  oneClickAddSvgSymbolUnknown: {
    d: `M ${7},${1}L ${1},${7}L ${7},${13}L ${13},${7}L ${7},${1} Z`
  },
  oneClickAddBtn: {
    height: '22px',
    width: '22px',
    borderWidth: '1px'
  },
  oneClickAddBtnParents: {
    height: '22px',
    width: '40px',
    borderWidth: '1px',
    borderRadius: 5,
    padding: '1px 1px'
  },
  oneClickAddParentsSvg: {
    height: '14px',
    width: '35px'
  },
  oneClickAddBtnLeft: {
    borderRight: 'none',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    padding: '1px 1px'
  },
  oneClickAddBtnMiddle: {
    borderLeft: 'none',
    borderRight: 'none',
    padding: '1px 1px'
  },
  oneClickAddBtnRight: {
    borderLeft: 'none',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    padding: '0px 0px'
  },
  dropright: {
    display: 'block',
    left: '80px',
    marginTop: '-5px'
  },
  colorScheme: {
    fill: '#bdbdbd',
    stroke: "#bdbdbd"
  },
  multiAddMenu: {
    position: 'absolute',
    display: 'block',
    left: '105px',
    minWidth: '35px'
  }
}

export const PartnerOptions = {
  SHOW_ALL_PARTNERS: 'show_all_partners',
  SHOW_AFFECTED_PARTNERS: 'show_affected_partners',
  HIDE_PARTNERS: 'hide_partners'
};

export const ViewStatusOptions = {
  IDENTIFIED: 'identified',
  DEIDENTIFIED: 'deidentified'
};

export const CookieKeys = {
  PARTNER_OPTION: 'famgenix_partners_option',
  PEDIGREE_VIEW_STATUS: 'famgenix_pedigree_view_status',
  PEDIGREE_LEGEND: 'famgenix_pedigree_legend',
  PEDIGREE_LEGEND_NOTES: 'famgenix_pedigree_legend_notes',
  PEDIGREE_ANCESTRY: 'famgenix_pedigree_ancestry',
  PEDIGREE_GENETIC_TESTING: 'famgenix_pedigree_genetic_testing',
  PEDIGREE_BLACK_AND_WHITE: 'famgenix_pedigree_black_and_white',
  PEDIGREE_NOTES: 'famgenix_pedigree_notes',
  ALWAYS_SHRINK: 'famgenix_always_shrink',
  PEDIGREE_PROGENY_ARCHIVE_DATA: 'famgenix_pedigree_progeny_archived_data',
  SIDEBAR_SHOW_MORE: 'sidebar_show_more',
  PEDIGREE_COLLAPSE_UNAFFECTED: 'famgenix_pedigree_collapse_unaffected',
  PEDIGREE_EXPANDED_COLLAPSED_GROUPS: 'famgenix_pedigree_expanded_collapsed_groups'
};
