import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';

import ErrorSummary from './error-summary'
import ActivityIndicator from './activity-indicator'

import * as patient_actions from '../store/patient/actions';
import * as model_health_history from '../model-states/m-health-history'
import ModalConfirmDelete from './modal-confirm-delete';

import geneResults from '../assets/json/gene-result.json'
import geneStatus from '../assets/json/gene-status.json'

import genetic_testing_api from '../api/genetic-testing-api'
import * as static_list_actions from '../store/static_list/actions'
import ControlSelectGene from './control-select-gene';
import { components } from "react-select";
import umls_api from "../api/umls-api";
import { cloneDeep, isNaN, debounce } from "lodash";
import Select from 'react-select';
class Expire extends Component {
  constructor(props) {
    super(props);

    this.state = {visible: true};
    this.timer = null;
  }

  componentDidMount() {
    this.setTimer();
    this.mounted = true;
  }

  setTimer() {
    // clear any existing timer
    this.timer = (this.timer != null) ? clearTimeout(this.timer) : null;

    // hide after `delay` milliseconds
    this.timer = setTimeout(function(){
      if (this.mounted) {
        this.setState({visible: false});
        this.timer = null;
      }
    }.bind(this), this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.mounted = false;
  }

  render() {
    if (this.state.visible) {
      return (<div>{this.props.children}</div>);
    }

    return (<span />);
  }
}

class MenuList extends Component {
  constructor(props) {
    super(props);
    this.unsureChosen = this.unsureChosen.bind(this);
  }

  queryUMLS() {
    // trigger event which queries UMLS endpoint
    const isIE = false || !!document.documentMode;
    var event
    if (isIE) {
      event = new CustomEvent('query-umls');
    } else {
      event = new Event('query-umls');
    }
    window.dispatchEvent(event);
  }

  queryMoreUMLS() {
    // trigger event which loads more UMLS results
    const isIE = false || !!document.documentMode;
    var event
    if (isIE) {
      event =  new CustomEvent('query-more-umls');
  } else {
      event = new Event('query-more-umls');
    }
    window.dispatchEvent(event);
  }

  unsureChosen(){
    let unsure = {value:'unsure', label: 'Unsure'}
    this.props.setValue(unsure)

   }

  render() {
    //React select filters options when searching, we want unsure to be able to be searched and also displayed all the time so added an option
    //of unsure to the options that include umls and also initial options that will allow it to be searched if no umls results
    let search_value = this.props.selectProps.inputValue
    if (Array.isArray(this.props.children)) {
      return (
        <React.Fragment>
          <components.MenuList {...this.props}>
            {this.props.children}
            <components.Option {...this.props}>
              <a id="unsureChoice" onClick={this.unsureChosen}>Unsure</a>
            </components.Option>
            <components.Option {...this.props}>
            {search_value.length >= 1 && (
              <a onClick={this.queryMoreUMLS}>Load More</a>
            )}
            </components.Option>
          </components.MenuList>
        </React.Fragment>
      );

    } else {

      this.queryUMLS();
      return (
        <React.Fragment>
          <components.MenuList {...this.props}>
            {(search_value.length == 0) && (
              <components.NoOptionsMessage {...this.props}>
                Search Genes
              </components.NoOptionsMessage>
            )}
            {(search_value.length > 0) && (
              <components.Option {...this.props}>
                  {(search_value.length >= 1) && (
                      <a id="unsureChoice" onClick={this.unsureChosen}>Unsure</a>
                  )}
              </components.Option>
            )}
              <Expire delay={5000}>
                <ActivityIndicator />
              </Expire>
          </components.MenuList>
        </React.Fragment>
      );

    }
  }
}


class ModalGeneBody extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      loading_delete: false,
      errorMessages: [],
      rkey: null,
      id: null,
      gene: null,
      result: null,
      resultLabel: null,
      status: null,
      variant: '',
      type: 'mutation_search',
      openModalConfirmDelete: false,
      modalConfirmDeleteHash: null,
      static_selected_gene: null,

      genetic_testings_options: [],
      search_val: "",

    }

    this.previous_search_val = "";
    this.umls_results_start = 0;

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.queryUMLS = this.queryUMLS.bind(this);
    this.searchUMLS = this.searchUMLS.bind(this);
    this.loadUmlsResults = this.loadUmlsResults.bind(this);
    this.queryMoreUMLS = this.queryMoreUMLS.bind(this);
    this.isGeneFromUMLS = this.isGeneFromUMLS.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.debouncedUpdateGene = debounce(this.props.onTextChange, 1000);

  }

  async componentDidMount() {

    if (this.props.geneDetail) {
      await this.setStaticGeneItem(this.props.geneDetail.umls_id, this.props.geneDetail.umls_name, this.props.geneDetail.gene);
      let result = this.props.geneDetail.result ? this.getLabelResult(this.props.geneDetail.result) : ""
      this.setState({
        rkey: this.props.geneDetail.rkey,
        id: this.props.geneDetail.id,
        gene: this.props.geneDetail.gene,
        umls_id: this.props.geneDetail.umls_id,
        umls_name: this.props.geneDetail.umls_name,
        gene_id: this.props.geneDetail.gene_id,
        result: result.value,
        variant: this.props.geneDetail.variant,
        status: this.props.geneDetail.status,
        type: this.props.geneDetail.type
      })
    } else {
      let gene_detail = model_health_history.createHistoryGene()
      this.setState({ rkey: gene_detail.rkey })
    }
    this.mounted = true;

    window.addEventListener('query-umls', this.queryUMLS, false);
    window.addEventListener('query-more-umls', this.queryMoreUMLS, false);
  }

  async componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('query-umls', this.queryUMLS, false);
    window.removeEventListener('query-more-umls', this.queryMoreUMLS, false);
  }



  queryUMLS(event) {
    const search_val = this.state.search_val;
    if (typeof(search_val) !== "string"
        || search_val.trim() === "") {
          return;
        }

    const trimmed_search_value = search_val.trim();
    if(trimmed_search_value.length <= 1) {
      return;
    }

    if (trimmed_search_value !== this.previous_search_val) {

      // using this value out of state because is used in triggering UMLS
      // we don't want to have the delay of updating redux state, we want it set immediately
      this.previous_search_val = trimmed_search_value;
      this.umls_results_start = 0;
      this.searchUMLS(trimmed_search_value, this.umls_results_start);

    }
  }

  async searchUMLS(term, start) {
    const payload = {term: term, start: start};
    let search_results = null;
    this.setState({umlsLoading: true})
    try {

      search_results = await umls_api.search_umls_gene(payload);
      this.umls_results_start += 10;
      if (search_results) {

        // sort by name alphabetically
        search_results.ConceptList = search_results.ConceptList.sort((a, b) => a.Name.localeCompare(b.Name))

        this.loadUmlsResults(search_results);
      }

    } catch (err) {
      // something went wrong with UMLS search
      console.log(err);
    }
    this.setState({umlsLoading: false})
  }

  loadUmlsResults(search_results) {
    let genetic_testing_optons = cloneDeep(this.state.genetic_testings_options);
    let existing_umls = [];
    let had_existing_umls = false;

    for(let i=0; i<genetic_testing_optons.length; i++) {
      if (genetic_testing_optons[i].label === "UMLS") {
        existing_umls = genetic_testing_optons[i].options;
        had_existing_umls = true;
        break;
      }
    }

    let existing_cui_dictionary = {};
    for (let i=0; i<existing_umls.length; i++) {
      existing_cui_dictionary[existing_umls[i].value] = existing_umls[i].value;
    }

    const concept_list = search_results["ConceptList"];
    if (Array.isArray(concept_list) && concept_list.length > 0) {

      for (let i=0; i<concept_list.length; i++) {
        const c = concept_list[i];
        const cui = c["CUI"];
        const name = c["Name"];
        const preferred_name = c["PreferredName"];

        if (cui in existing_cui_dictionary) {
          for (let x=0; x<existing_umls.length; x++) {
            if (existing_umls[x].value === cui) {
              existing_umls[x].label = name;
              break;
            }
          }
        } else {

          existing_umls.push({
            value: cui,
            label: name
          });

        }
      }
    }

    existing_umls.sort((a,b)=>{
        if (a.label.length < b.label.length) return -1
        if (a.label.length > b.label.length) return 1
        return 0
    })

    if (had_existing_umls) {

      for(let i=0; i<genetic_testing_optons.length; i++) {
        if (genetic_testing_optons[i].label === "UMLS") {
          genetic_testing_optons[i].options = existing_umls;
          break;
        }
      }

    } else {
      genetic_testing_optons.push({
        label: "UMLS",
        options: existing_umls
      });
    }

    this.setState({ genetic_testings_options: genetic_testing_optons });
  }

  queryMoreUMLS(event) {
    const search_val = this.state.search_val;
    if (typeof(search_val) !== "string"
        || search_val.trim() === "") {
          return;
        }

    const trimmed_search_value = search_val.trim();
    if(trimmed_search_value.length <=1) {
      return;
    }

    if (trimmed_search_value !== this.previous_search_val) {

      // using this value out of state because is used in triggering UMLS
      // we don't want to have the delay of updating redux state, we want it set immediately
      this.previous_search_val = trimmed_search_value;
      this.umls_results_start = 0;
      this.searchUMLS(trimmed_search_value, this.umls_results_start);

    } else {
      this.searchUMLS(trimmed_search_value, this.umls_results_start);
    }
  }


  isGeneFromUMLS(gene_id) {
    gene_id = gene_id + "";
    const is_from_umls = gene_id.substr(0,1) === "C";
    return is_from_umls
  }

  handleSearchInputChange(search_val, action) {
    this.setState({ search_val: search_val });
  }

  handleChange(e) {
    // this.props.updateGene( {[e.target.name] : e.target.value })
    this.setState({ [e.target.name]: e.target.value })
  }

  validateDropDown(key) {
    return (this.state[key] !== null)
  }

  validate() {
    let errorMessages = []
    if (this.state.gene_id === null || this.state.umls_id === '') {
      errorMessages.push('Gene is required')
    }

    this.setState({ errorMessages })
    return errorMessages.length == 0
  }

  handleSelectChange(item) {
    this.props.updateGene(item)
    this.setState({ ...item })
  }

  handleTextChange(item) {
    this.setState({ ...item });
    this.debouncedUpdateGene(item);
  }

  handleSelectGene(gene){
    let gene_id = (gene !== null) ? gene.value : null
    let gene_name = (gene !== null) ? gene.label : null
    if(gene_id !== null){
      let umls_id = this.isGeneFromUMLS(gene_id) ? gene_id : null
      this.props.updateGene({ 
        "gene": gene_name,
        "umls_id": umls_id,
        "umls_name": gene_name
       })
    }
    this.setStaticGeneItem(gene_id, gene_name);
    this.setState({ gene_id, gene_name, gene});

  }

  setStaticGeneItem(gene_id, umls_gene_name, gene_name){ 
  let static_selected_gene={
      id: gene_id,
      gene_name: gene_name || umls_gene_name
  }
  this.setState({ static_selected_gene: static_selected_gene });
}

  onClickOpenModalConfirmDelete() {
    this.setState({
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  async onClickDelete() {
    this.props.onDelete();
  }


  getGeneLabel(id) {
    let genetic_testings = this.props.static_list.genetic_testings;
    if (genetic_testings.length == 0) return '';
    var gene = genetic_testings.find(item => item.id == id)

    if (typeof (gene) === 'undefined') return ''
    return gene.gene;

  }

  getLabelResult(result) {

    if (geneResults.length == 0) return '';
    let gene_result = geneResults.find(item => item.value == result || item.label == result)

    if (typeof (result) === 'undefined') return ''
    return gene_result
  }

  getLabelStatus(id) {
    if (geneStatus.length == 0) return '';
    var status = geneStatus.find(item => item.value == id || item.value == id)

    if (typeof (status) === 'undefined') return ''
    return status.label;
  }

  render() {
    let static_selected_gene = this.state.static_selected_gene
    let gene_result = this.getLabelResult(this.state.result)
    let gene = (static_selected_gene) ? {value: static_selected_gene.id, label: static_selected_gene.gene_name} : null;
    let result = gene_result ? { value: gene_result.value, label:  gene_result.label}: null;
    let status = { value: this.state.status, label: this.getLabelStatus(this.state.status)};
    let showLables = this.props.index === 0 ? true : false;
    return(
      // <div className='row' style={{overflow: 'visible', position: 'relative'}}>
      <div className='row'>

        {this.state.openModalConfirmDelete &&
          <ModalConfirmDelete
            title="Delete Gene"
            message="Do you want to delete this gene?"
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() => this.setState({ openModalConfirmDelete: false })}
            onOk={() => this.onClickDelete()}
            loading={this.state.loading_delete}
            errorMessages={this.state.errorMessages}
          />
        }
        <div className="col-md-2">
          <div className="form-group">
          {showLables &&(<label>Gene</label>)}
            <div className='gene-input-height'>
              <Select
                classNamePrefix="custom-react-select"
                id="select-diseases-container"
                className="react-select-container"
                options={this.state.genetic_testings_options}
                value={gene}
                onChange={(item) => this.handleSelectGene(item)}
                isLoading={this.state.umlsLoading}
                components={{ MenuList, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                inputValue={this.state.search_val}
                onInputChange={(input_val, action) => this.handleSearchInputChange(input_val, action)}
                placeholder="&nbsp;"
                menuPortalTarget={document.body}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: '34px',
                    minHeight: '34px',
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999, 
                  }),
                
                }}        
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            {showLables &&(<label>Result</label>)}
            <div className="custom-select gene-dropdown">
            <Select
                classNamePrefix="custom-react-select"
                id="select-diseases-container"
                className="react-select-container gene-select"
                options={[
                  { value: '', label: " " }, 
                  ...geneResults,
                ]}
                onChange={(item) => {
                  let result = (item) ? item.value : null;
                  this.handleSelectChange({ result })
                }}
                value={result}
                placeholder="&nbsp;"
                menuPortalTarget={document.body} // Render dropdown menu outside the modal
                styles={{
                  control: (base) => ({
                    ...base,
                    height: '34px', 
                    minHeight: '34px', 
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                
                }}       
              />
            </div>
          </div>
        </div>

        <div className="col-md-2">
          <div className="form-group">
          {showLables &&(<label>Variant</label>)}
            <input
              name="variant"
              type="text"
              value={this.state.variant}
              onChange={(e) =>this.handleTextChange({'variant': e.target.value})}
              class="form-control" />
          </div>
        </div>

        {(this.props.selectedPanel === "" &&
        <div className="col-md-3">
          <div className="form-group">
            {showLables &&(<label>Confirmed</label>)}
            <div className="custom-select gene-dropdown">
            <Select
              classNamePrefix="custom-react-select"
              id="select-diseases-container"
              className="react-select-container"
              options={[
                { value: '', label: " " },
                ...geneStatus,
              ]}
              onChange={(item) => {
                let status = (item) ? item.value : null;
                this.handleSelectChange({ status })
              }}
              value={status}
              placeholder="&nbsp;"
              menuPortalTarget={document.body}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '34px',
                  minHeight: '34px', 
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              
              }}       
            />
            </div>
          </div>
        </div>
        )}

        <div className="col-md-2">
          <div className="form-group">
          {showLables &&(<label>Variant-Specific
                <span className='i-tooltip-left'>
                  <i className="fa fa-info-circle"></i>
                  <span className="i-tooltiptext-left">
                    Tested for a specific variant of the mutation. For example,
                    when another family member has already tested positive.
                  </span>
                </span>
            </label>)}
            <div className="checkbox gene-Modal">
              <label className="switch">
                <input
                  name="variant-specific"
                  id="variant-specific"
                  onChange={() => {
                    let type = (this.state.type === 'direct_gene_test') ? 'mutation_search' : 'direct_gene_test';
                    let item = {'type': type}
                    this.handleSelectChange(item);
                  }}
                  checked={this.state.type === 'direct_gene_test'}
                  type="checkbox" />
                <span className="slider round"></span>
              </label>
              {this.props.geneDetail &&
                <a className="remove-row-gene-modal" onClick={() => this.onClickOpenModalConfirmDelete()}><i className="fa fa-trash"></i> </a>
              }
         </div>
          </div>
        </div>

      </div>
    );
  }

}

const redux_state = state => ({
  static_list: state.static_list
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(ModalGeneBody);
