import React, { Component } from 'react';
import { isEqual, debounce } from 'lodash';
import { log_error } from '../../helpers/helper-error';
import HealthInputs from './health-inputs';
import * as helper_family_tree from '../../helpers/helper-family-tree';
import FamilyTreePartner from './partner';
import helpers from '../../helpers';
import family_api from '../../api/family-api';

class FamilyTreeSibling extends Component {

  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
      loading: false,

      collapsed: true,
      partners: null,
      siblingRkey: null,
    }
    this.updateFirstName = debounce(this.updateFirstName, 250)
  }

  async componentDidUpdate(prevProps) {
    this.loadPartnersFromRedux()
  }

  loadPartnersFromRedux() {
    if(this.state.siblingRkey == null) return

    if (!(this.state.siblingRkey in this.props.patient.partners)) return

    let partners = this.props.patient.partners[this.state.siblingRkey]
    
    for(var partner of partners) {
      let relKey = partner.rkey
      if(relKey.includes('-fake')) partners.pop(partner)
    }

    if(!isEqual(this.state.partners, partners)) {
      this.setState({partners});
    }
  }

  componentDidMount() {
    if ('collapsed' in this.props) this.setState({ collapsed: this.props.collapsed});
    this.setState({siblingRkey: this.props.sibling.rkey});
  }

  onCollapseClick(e) {
    this.setState({ collapsed: !this.state.collapsed });
    this.clearSelection();
  }

  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }

  async onChangeName(first_name) {
    try {
      let profile = { rkey: this.props.sibling.rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      helper_family_tree.saveSiblingDetailToRedux(this.props.dispatch, profile)
      this.updateFirstName(first_name);
    } catch (error) {
      log_error(this.constructor.name, error);
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.sibling
      await family_api.patch_member_memberid(member.id, {
        first_name
      })
    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  getFirstName() {
    let profile = this.getProfile();
    if(profile == null) return null;
    return profile.first_name;
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.sibling.rkey)
  }

  getPartnersCount() {
    if(this.state.partners) return this.state.partners.length

    return 0
  }

  async onClickDeleteConfirmed() {
    try {

      this.setState({errorMessages: [], loading: true})

      await this.props.deleteMember(this.props.sibling)

      helper_family_tree.deleteSibling(this.props.dispatch, this.props.patient, this.props.sibling);
      this.setState({ openModalConfirmDelete: false })

    } catch (error) {
      helpers.logger(error)
      this.setState({errorMessages: [error.message]})
    } finally {
      this.setState({loading: false})
    }
  }

  render() {

    return <React.Fragment>

      <tr>
        <td>
          {this.getPartnersCount() > 0 &&
            <a onClick={(e) => this.onCollapseClick()}
              className="float--right">
              <i className={"fa fa-" + (this.props.collapsed ? "minus-square" : "plus-square")}></i>
            </a>
          }
        </td>
        <td className="td--nowrap">

          { this.props.label }

        </td>
        <td>
          <input
            onChange={(e) => this.onChangeName(e.target.value)}
            value={this.getFirstName()}
            type="text" className="inputreg form-control normal-input-text"/>
        </td>

        <HealthInputs
          for="sibling"
          patient={this.props.patient}
          dispatch={this.props.dispatch}
          errorMessages={this.state.errorMessages}
          loading={this.state.loading}
          ownerRkey={this.props.sibling.rkey}
          collapsible={this.state.partners && this.state.partners.length > 0}
          collapsed={this.state.collapsed}
          onCollapse={() => this.onCollapseClick() }
          canDelete={true}
          onDeleteConfirmed={async () => await this.onClickDeleteConfirmed() }
          profile={this.getProfile()}
        />

      </tr>

      { this.state.collapsed && this.state.partners &&
        this.state.partners.map((partner,index) => {
          return <FamilyTreePartner
            key={partner.rkey}
            ownerRkey={this.state.siblingRkey}
            collapsed={true}
            level={0}
            extra_indent={1}
            label={'Partner #' + (index + 1) }
            owner={this.props.sibling}
            partner={partner}
            parent_side={this.props.parent_side}
            dispatch={this.props.dispatch}
            patient={this.props.patient}
            deleteMember={this.props.deleteMember}
          />
        })
      }

    </React.Fragment>

  }
}

export default FamilyTreeSibling;
