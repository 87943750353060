import React, { Component } from 'react';
import Select from "react-select";

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const filterOptions = (search, input) => {
  if (input && input.length > 0) {
    if(search.data.fromUMLS){
      return true
    }else if(search.label){
      return search.label.toLowerCase().includes(input.toLowerCase())
    }
  }
  return true;
};

const ControlSelectDiseases = (props) => {
  return (
    <Select      
      classNamePrefix="custom-react-select"
      id="select-diseases-container"
      className="react-select-container"
      // className="custom-react-select-container"
      // classNamePrefix="react-select"
      onChange={(item) => props.onChange(item)}
      value={props.value}
      options={props.options}
      formatGroupLabel={formatGroupLabel}
      placeholder={props.placeholder}
      isLoading={props.isLoading}
      isDisabled={props.disabled}
      components={props.components}
      inputValue={props.inputValue}
      onInputChange={props.onInputChange}
      autoFocus={props.autofocus}
      openMenuOnFocus
      filterOption={filterOptions}
    />
  )
}

export default ControlSelectDiseases
