import React, { Component } from 'react';
import { connect } from "react-redux";
import ModalGeneTestForm from './modal-gene-test-form'

import genetic_testing_api from '../api/genetic-testing-api'
import * as static_list_actions from '../store/static_list/actions'
import * as patient_actions from '../store/patient/actions';
import geneResults from '../assets/json/gene-result.json'
import geneStatus from '../assets/json/gene-status.json'
import { cloneDeep, isEmpty } from "lodash";
import { createUUID } from './react-flow-pedigree/utils';

import ModalConfirmDelete from './modal-confirm-delete';

class PatientGeneTestView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showModalGeneTest: false,
      selectedGeneDetail: null,
      modalGeneTestTitle: '',
      openModalConfirmDelete: false,
      modalConfirmDeleteHash: null,
      loading_delete: false,
      errorMessages: []
    }

    this.handleClickAddGeneTest = this.handleClickAddGeneTest.bind(this)
    this.handleEditGeneTest = this.handleEditGeneTest.bind(this)
    this.disableAddForReadOnly = this.disableAddForReadOnly.bind(this)
    this.getGeneTests = this.getGeneTests.bind(this)
    this.getGenePanels = this.getGenePanels.bind(this)
    this.getPanelName = this.getPanelName.bind(this)
    this.renderGenes = this.renderGenes.bind(this)
  }

  async componentDidMount() {
    // await this.loadGeneticTestingsFromAPI()
  }

  async loadGeneticTestingsFromAPI() {
    try {
      let genetic_testings = this.props.static_list.genetic_testings;
      if(genetic_testings.length == 0) {
        let data = await genetic_testing_api.get_genetic_testing();
        genetic_testings = data.sort((a, b) => {
          if(a.gene < b.gene) return -1;
          if(a.gene > b.gene) return 1;
          return 0;
        });
        this.props.dispatch(static_list_actions.initialize_genetic_testings(genetic_testings));
      }

    } catch (error) {
      console.log(error)
    }
  }


  async handleClickAddGeneTest() {
    if('onBeforeAddGene' in this.props) {
      var ok = await this.props.onBeforeAddGene();
      if(ok) {
        this.setState({showModalGeneTest: true, selectedGeneDetail: null, modalGeneTestTitle: 'Add Gene'})
      }
    } else {
      this.setState({showModalGeneTest: true, selectedGeneDetail: null, modalGeneTestTitle: 'Add Gene'})
    }
  }

  getGeneTests() {
    let history_genes = this.props.getPedigreeData !== null && this.props.getPedigreeData !== undefined ? this.props.getPedigreeData().genes : this.props.history_genes
    if(this.props.patientRkey in history_genes) {
      let geneTests = history_genes[this.props.patientRkey];
      let genesDisplay = [];
      for(let test of geneTests) {
        if(test.rkey === null || test.rkey === undefined) test.rkey = createUUID();
        if('member_genes' in test) continue;
        genesDisplay.push(test);
      }
      return genesDisplay;
    }
    return []
  }

  diseaseGroup(id) {
    if(1 <= id <= 43) {
      return 'Cancer';
    } else {
      return 'Other Conditions';
    }
  }

  handleEditGeneTest(selectedGeneDetail) {
    this.setState({showModalGeneTest: true, selectedGeneDetail, modalGeneTestTitle: 'Edit Gene'})
  }


  getLabelResult(id) {
    if(geneResults.length == 0) return '';
    var result = geneResults.find(item => item.value == id || item.label == id)

    if(typeof(result) === 'undefined') return ''
    return result.label;
  }

  getLabelStatus(id) {
    if(geneStatus.length == 0) return '';
    var status = geneStatus.find(item => item.value == id)

    if(typeof(status) === 'undefined') return ''
    return status.label;
  }

  onModalGeneTestSave(data) {
    //if('onGeneTestUpdate' in this.props) this.props.onGeneTestUpdate(data);
    this.setState({showModalGeneTest: false});
  }

  onModalGeneTestDelete(data) {
    //if('onGeneTestUpdate' in this.props) this.props.onGeneTestUpdate(data);
    this.setState({showModalGeneTest: false});
    if(this.state.openModalConfirmDelete === true) this.setState({openModalConfirmDelete: false})
  }

  onClickOpenModalConfirmDelete(selectedGeneDetail) {
    this.setState({
      selectedGeneDetail,
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  async onClickDelete() {
    try {
      this.setState({ errorMessages: [], loading_delete: true })

      // delete genetic testing
      if(this.state.selectedGeneDetail.id !== null) {
        await genetic_testing_api.delete_member_genetic_testing_id(this.state.selectedGeneDetail.id)
      }

      if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
        let genes = cloneDeep(this.props.getPedigreeData().getGenes(this.props.patientRkey))
        let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey))
        genes = genes.filter(gene => gene.id !== this.state.selectedGeneDetail.id)
        profile_proband.genetic_testing = genes;
        await this.props.getPedigreeData().setGenes(this.props.patientRkey, genes)
        await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband)
      }
      else{
        this.props.dispatch(patient_actions.delete_history_gene_detail({
          ownerRkey: this.props.patientRkey,
          gene_detail_rkey: this.state.selectedGeneDetail.rkey
        }));
      }



      if('onGeneTestUpdate' in this.props) {
        this.onModalGeneTestDelete()
      } else {
        this.setState({showModalGeneTest: false})
      }

    } catch (error) {
      this.setState({ errorMessages: [error.message] })
    } finally {
      this.setState({ loading_delete: false })
    }
    this.setState({openModalConfirmDelete: false})
  }

  disableAddForReadOnly(){
    if(this.props.read_only && this.props.read_only === true){
      return false
    }
    return true
  }


  getGenePanels() {
    let history_genes = this.props.getPedigreeData !== null && this.props.getPedigreeData !== undefined ? this.props.getPedigreeData().genePanels : this.props.history_genes
    if(this.props.patientRkey in history_genes) {
      let geneTests = history_genes[this.props.patientRkey];
      let genePanelsDisplay = [];
      for(let test of geneTests) {
        if(test.rkey === null || test.rkey === undefined) test.rkey = createUUID();
        if('member_genes' in test) genePanelsDisplay.push(test);
      }
      return genePanelsDisplay;
    }
    return []
  }
  getPanelName(panelId) {
    const panels = this.props.static_list.genetic_testin_panels;
    if (!panels) return panelId;
  
    const panel = panels[panelId];
    if (panel && panel.labs) {
      let lab = Object.values(this.props.static_list.genetic_testings_labs).find(
        (lab) => lab.id === panel.labs
      );
      let lab_name = null;
      if (lab) {
        lab_name = lab.lab
        return lab_name + ' - ' + panel.panel_name;
      }
    }
    return panel?.panel_name || panelId;
  }

  renderGenes(){
    const geneTests = this.getGenePanels();
  
    if(isEmpty(geneTests)) return <> </>
        return Object.keys(geneTests).map(key => {
            const { member_genes: tests, panel_status, panel_id, panel_notes } = geneTests[key];
            const is_pending = panel_status === 'pending';
            const panel_name = this.getPanelName(panel_id);
            
            const nonNegativeResults = Object.values(tests).filter(
              ({ result }) => result && result !== 'n' && result !== 'Negative'
            );
            const noResults = Object.values(tests).every(
              ({ result }) => !result
            );
  
            return (panel_name !== '' && panel_name !== undefined && panel_name !== null) ? (
                <div 
                  className={`panel-summary ${this.props.sideBar ? 'width--full' : ''}`}
                >
                  {this.disableAddForReadOnly() && (
                    <div className="panel-summary-actions">
                      <a 
                        className="panel-summary-edit" 
                        onClick={() => this.handleEditGeneTest(geneTests[key])}
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                      <a 
                        className="panel-summary-delete" 
                        onClick={() => this.onClickOpenModalConfirmDelete(geneTests[key])}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </div>
                  )}
    
                  <h4>{panel_name}</h4>
  
                  {nonNegativeResults.length > 0 ? (
                          nonNegativeResults.map(({ hash_id, gene, umls_name, result, variant, status }, index) => (
                            <p key={hash_id}>
                              <b> {gene || umls_name} </b> - {this.getLabelResult(result)}
                              {variant && this.getLabelResult(result) ? `, ${variant}` : `${variant}`}
                              {this.getLabelStatus(status) ? (this.getLabelResult(result) || variant ? ', ' + this.getLabelStatus(status) : this.getLabelStatus(status)) : null}
                            </p>
                        ))
                    ) : (panel_status === 'canceled' && noResults) ? (
                      <p> Canceled </p>
                    ) :(is_pending || noResults ) ? (
                      <p> Pending </p>
                    ) :  (
                      <p>Negative Genetic Testing</p>
                    )}
  
                    {panel_notes && <p>{panel_notes}</p>}
  
                </div>
    
            ) : (
              <div 
              className={`panel-summary ${this.props.sideBar ? 'width--full' : ''}`}
              >
                  {this.disableAddForReadOnly() && (
                    <div className="panel-summary-actions">
                      <a 
                        className="panel-summary-edit" 
                        onClick={() => this.handleEditGeneTest(geneTests[key])}
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                      <a 
                        className="panel-summary-delete" 
                        onClick={() => this.onClickOpenModalConfirmDelete(geneTests[key])}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </div>
                  )}              
                {Object.values(tests).map((gene, index, array) => (
                    <p>
                      <b>{gene.umls_name || gene.gene}</b> - {this.getLabelResult(gene.result)}
                      {gene.variant && this.getLabelResult(gene.result) ? `, ${gene.variant}` : `${gene.variant}`}
                      {this.getLabelStatus(gene.status) ? (this.getLabelResult(gene.result) || gene.variant ? ', ' + this.getLabelStatus(gene.status) : this.getLabelStatus(gene.status)) : null}
                    </p>
                ))}
  
                {panel_notes && <p>{panel_notes}</p>}              
  
              </div>
              
              )
      });
    }
  
  render() {
    return (
      <React.Fragment>
        <div className="row col-md-6 pull-right margin-one" style={{paddingRight: "2%"}}>
          <div className="col-md-12 margin-one no-margin-top">
            <div className={(this.props.sideBar ? "form-header-2" : "form-header")}>
              <div className="row vcenter">
                <div className="col-md-6 ">
                <h3 className={"title " + (this.props.sideBar ? 'text--uppercase' : '')}>Genetic Testing</h3>
                </div>
              {this.disableAddForReadOnly() && (
                <div className="col-md-6 ">

                  {this.props.sideBar ?
                    <a className="btn btn-teal btn-xs" onClick={this.handleClickAddGeneTest}><i className="fa fa-plus"></i> Add</a>
                  :
                    <a id="add-gene-btn" className="btn btn-teal btn-xs" onClick={this.handleClickAddGeneTest}><i className="fa fa-plus"></i> Add</a>
                  }

                </div>
              )}
              </div>
            </div>
            <div className="panel-col-gene">
              {this.renderGenes()}
              {this.getGeneTests().map((item, index, array) => (
                <div key={item.rkey} className={"panel-summary " + (this.props.sideBar ? 'width--full' : '')}>
                {this.disableAddForReadOnly() && (
                  <>
                  <a className="panel-summary-edit" onClick={() => this.handleEditGeneTest(item)}><i className="fa fa-pencil-square-o"></i></a>
                  <a className="panel-summary-delete" onClick={() => this.onClickOpenModalConfirmDelete(item)}><i className="fa fa-trash"></i></a>
                  </>
                )}
                  <p>
                    <b>{item.gene || item.umls_name}</b> - {this.getLabelResult(item.result)}
                    {item.variant && this.getLabelResult(item.result) ? `, ${item.variant}` : `${item.variant}`}
                    {this.getLabelStatus(item.status) ? (this.getLabelResult(item.result) || item.variant ? ', ' + this.getLabelStatus(item.status) : this.getLabelStatus(item.status)) : null}
                  </p>
                </div>
              ))}

            </div>
          </div>
        </div>

        {this.state.openModalConfirmDelete &&
          <ModalConfirmDelete
            title="Delete Gene"
            message="Do you want to delete this gene?"
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() => this.setState({ openModalConfirmDelete: false })}
            onOk={() => this.onClickDelete()}
            loading={this.state.loading_delete}
            errorMessages={this.state.errorMessages}
          />
        }

        {this.state.showModalGeneTest &&
          <ModalGeneTestForm
            title={this.state.modalGeneTestTitle}
            patientRkey={this.props.patientRkey}
            profile={this.props.profile}
            geneDetail={this.state.selectedGeneDetail}
            onClose={() => this.setState({showModalGeneTest: false})}
            onCancel={() => this.setState({showModalGeneTest: false})}
            onSave={(data) => this.onModalGeneTestSave(data)}
            onDelete={() => this.onModalGeneTestDelete()}
            getPedigreeData={this.props.getPedigreeData}
          />
        }



      </React.Fragment>
    )
  }
}

const redux_state = state => ({
  history_diseases: state.patient.history_diseases,
  history_genes: state.patient.history_genes,
  static_list: state.static_list
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(PatientGeneTestView);
