export class PedigreeData {
	constructor() {
		this.proband = null;
		this.profiles = {};
		this.hidden_disease_colors = [];
		this.diseases = {};
		this.genes = {};
		this.genePanels = {};
		this.partners = [];
		this.uncles_aunts = [];
		this.siblings = [];
		this.sons_daughters = [];
		this.risk_criteria = [];
		this.risk_factors = {};
		this.risk_results = {};
		this.risk_settings = {};
		this.documents = {};
		this.pedigree_notes = "";
		this.saved_node_positions = [];
		this.progeny_archive_data = null;
		this.progeny_archive_preferences = [];
		this.probands = [];
		this.probands_unlinked = [];
		this.member_links = [];
		this.cloned_members = [];
		this.linked_members = [];
		this.consanguineous_connection_active = false;
		this.reassign_parents_connection_active = false;
		this.donor_assign = {};
		this.donors = [];
		this.sibling_orders = [];
		this.collapsible = [];
		this.proband_relation_tree = {};
		this.expanded_collapsed_groups = [];
	}

	getProband() {
		return this.proband;
	}

	setProband(proband) {
		this.proband = proband;
		this.profiles[this.proband.rkey] = proband;
	}

	setClonedMembers(cloned_members) {
		this.cloned_members = cloned_members;
	}

	getClonedMembers(){
		return this.cloned_members;
	}

	setLinkedMembers(linked_members){
		this.linked_members = linked_members;
	}

	getLinkedMembers(){
		return this.linked_members;
	}

	setProbandDetail(key, value){
		this.proband[key] = value
		this.profiles[this.proband.rkey][key] = value
	}

	getAllProfiles() {
		return this.profiles;
	}

	getProfile(key) {
		if (key in this.profiles) {
			return this.profiles[key];
		}
		return null;
	}

	setProfile(key, profile) {
		if (profile.twin_set === null) {
			profile.twin_type = null;
		}

		//making sure that ancestry is in correct format in each setting of profile that involves the proband
		if(profile.mother){
			if(profile.mother.ancestry !== null && profile.mother.ancestry !== undefined){
				profile.mother.ancestry = profile.mother.ancestry.length > 0 ?
				profile.mother.ancestry.map(item => {
				  let label = item.display_name ? item.display_name: item.label;
				  let value = item.id ? item.id : item.value
				  return ({label: label, value: value})}) : null;
			}
		}

		if(profile.father){
			if(profile.father.ancestry !== null && profile.father.ancestry !== undefined){
				profile.father.ancestry = profile.father.ancestry.length > 0 ?
				profile.father.ancestry.map(item => {
					let label = item.display_name ? item.display_name: item.label;
					let value = item.id ? item.id : item.value
					return ({label: label, value: value})}) : null;
			}
		}

		if (key in this.profiles) {
			this.profiles[key] = Object.assign({}, this.profiles[key], profile)
			if(this.profiles[key].is_proband){
				this.proband = Object.assign({}, this.proband, profile)
			}
		} else {
			this.profiles[key] = profile
			if(this.profiles[key].is_proband){
				this.proband = profile
			}
		}

		//Also update the data stores below if existing
		if(this.partners[key] !== null && this.partners[key] !== undefined){
			this.partners[key] = this.profiles[key]
		}

		let people = Object.values(this.profiles)

		if(this.uncles_aunts['paternal'].find(person => person.rkey === key) !== null && this.uncles_aunts['paternal'].find(person => person.rkey === key) !== undefined){
			for(let uncle_aunt of this.uncles_aunts['paternal']){
				if(uncle_aunt.rkey == key){
					uncle_aunt = this.profiles[key]
				}
			}
		}
		else{
			//check here if paternal aunt or uncle of proband
			let paternal_grandfather = this.proband.paternal_grandfather
			let paternal_grandmother = this.proband.paternal_grandmother

			//get children of grandparents except the proband's father
			let paternal_grandfather_children = people.filter(person => (person.father_id === paternal_grandfather.id || person.mother_id === paternal_grandfather.id) && person.id != this.proband.father.id)
			let paternal_grandmother_children = people.filter(person => (person.father_id === paternal_grandmother.id || person.mother_id === paternal_grandmother.id) && person.id != this.proband.father.id)

			let paternal_uncles_aunts = paternal_grandfather_children.concat(paternal_grandmother_children)

			let is_paternal_uncle_aunt = paternal_uncles_aunts.find(person => person.rkey === key)

			if(is_paternal_uncle_aunt){
				this.uncles_aunts['paternal'].push(this.profiles[key])
			}
		}

		if(this.uncles_aunts['maternal'].find(person => person.rkey === key) !== null && this.uncles_aunts['maternal'].find(person => person.rkey === key) !== undefined){
			for(let uncle_aunt of this.uncles_aunts['maternal']){
				if(uncle_aunt.rkey == key){
					uncle_aunt = this.profiles[key]
				}
			}
		}
		else{
			//check here if maternal aunt or uncle of proband
			let maternal_grandfather = this.proband.maternal_grandfather
			let maternal_grandmother = this.proband.maternal_grandmother

			//get children of grandparents except the proband's mother
			let maternal_grandfather_children = people.filter(person => (person.father_id === maternal_grandfather.id || person.mother_id === maternal_grandfather.id) && person.id != this.proband.mother.id)
			let maternal_grandmother_children = people.filter(person => (person.father_id === maternal_grandmother.id || person.mother_id === maternal_grandmother.id) && person.id != this.proband.mother.id)

			let maternal_uncles_aunts = maternal_grandfather_children.concat(maternal_grandmother_children)

			let is_maternal_uncle_aunt = maternal_uncles_aunts.find(person => person.rkey === key)

			if(is_maternal_uncle_aunt){
				this.uncles_aunts['maternal'].push(this.profiles[key])
			}
		}


		if(this.siblings.find(person => person.rkey === key) !== null && this.siblings.find(person => person.rkey === key) !== undefined){
			for(let sibling of this.siblings){
				if(sibling.rkey == key){
					sibling = this.profiles[key]
				}
			}
		}
		else{
			// check here if sibling of proband
			let father = this.proband.father
			let mother = this.proband.mother

			//get children of parents except the proband
			let father_children = people.filter(person => (person.father_id === father.id || person.mother_id === father.id) && person.id != this.proband.id)
			let mother_children = people.filter(person => (person.father_id === mother.id || person.mother_id === mother.id) && person.id != this.proband.id)

			let proband_siblings = father_children.concat(mother_children)

			let is_proband_sibling = proband_siblings.find(person => person.rkey === key)

			if(is_proband_sibling){
				this.siblings.push(this.profiles[key])
			}
		}

		let in_sons_daughters = false;
		for(let sons_daughters_key in this.sons_daughters){
			if(this.sons_daughters[sons_daughters_key].find(person => person.rkey === key)) in_sons_daughters = true;
		}

		if(in_sons_daughters){
			for(let sons_daughter_key in this.sons_daughters){
				for(let person of this.sons_daughters[sons_daughter_key]){
					if(person.rkey == key){
						person = this.profiles[key]
					}
				}
			}
		}
		else{
			// check here if child of proband
			let children_of_proband = people.filter(person => person.father_id === this.proband.id || person.mother_id === this.proband.id)
			let is_child_of_proband = children_of_proband.find(person => person.rkey === key)

			let father = this.proband.father
			let mother = this.proband.mother

			let father_siblings = people.filter(person => person.father_id === father.father_id && person.mother_id === father.mother_id && person.id !== father.id && person.father_id && person.mother_id)
			let mother_siblings = people.filter(person => person.father_id === mother.father_id && person.mother_id === mother.mother_id && person.id !== mother.id && person.father_id && person.mother_id)

			let cousins_of_proband = [];
			for (let sibling of father_siblings) {
			  for (let partner of sibling.partners) {
				let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
				cousins_of_proband = cousins_of_proband.concat(cousins)
			  }
			}

			for (let sibling of mother_siblings) {
			  for (let partner of sibling.partners) {
				let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
				cousins_of_proband = cousins_of_proband.concat(cousins)
			  }
			}

			// check if cousin of proband
			let is_cousin_of_proband = cousins_of_proband.find(cousin => cousin.rkey === key)

			if(is_child_of_proband){
				if(this.sons_daughters[this.proband.rkey]){
					this.sons_daughters[this.proband.rkey].push(this.profiles[key])
				}
				else{
					for(let partner of this.proband.partners){
						let children_with_proband = people.filter(person => (person.father_id === this.proband.id && person.mother_id === partner.id) || (person.father_id === partner.id && person.mother_id === this.proband.id))
						let is_child_with_proband = children_with_proband.find(person => person.rkey === key)

						if(this.sons_daughters[partner.rkey]){
							if(is_child_with_proband){
								this.sons_daughters[partner.rkey].push(this.profiles[key])
							}
						}
						else{
							this.sons_daughters[this.proband.rkey] = [];
							this.sons_daughters[this.proband.rkey].push(this.profiles[key])
						}
					}
				}
			}

			if(is_cousin_of_proband){
				if(this.sons_daughters[is_cousin_of_proband.father_id]){
					this.sons_daughters[is_cousin_of_proband.father_id].push(this.profiles[key])
				}
				else if (this.sons_daughters[is_cousin_of_proband.mother_id]){
					this.sons_daughters[is_cousin_of_proband.mother_id].push(this.profiles[key])
				}
				else{
					this.sons_daughters[is_cousin_of_proband.father_id] = [];
					this.sons_daughters[is_cousin_of_proband.father_id].push(this.profiles[key])
				}
			}
		}



	}

	setAllProfiles(profiles) {
		this.profiles = profiles;
	}

	deleteProfile(key) {
		if (this.donors.find(donor => donor.donor_id + '' === this.profiles[key].id + '')) this.donors = this.donors.filter(donor => donor.donor_id + '' != this.profiles[key].id + '');
		if (key in this.profiles) delete this.profiles[key];
		if (this.uncles_aunts['maternal'].find(person => person.rkey === key)) this.uncles_aunts['maternal'] = this.uncles_aunts['maternal'].filter(person => person.rkey != key);
		if (this.uncles_aunts['paternal'].find(person => person.rkey === key)) this.uncles_aunts['paternal'] = this.uncles_aunts['paternal'].filter(person => person.rkey != key);
		if (this.siblings.find(person => person.rkey === key)) this.siblings = this.siblings.filter(person => person.rkey != key);

		let in_sons_daughters = false;
		for(let sons_daughter_key in this.sons_daughters){
			if(this.sons_daughters[sons_daughter_key].find(person => person.rkey === key)) in_sons_daughters = true;
		}

		if (in_sons_daughters){
			for(let sons_daughter_key in this.sons_daughters){
				this.sons_daughters[sons_daughter_key] = this.sons_daughters[sons_daughter_key].filter(person => person.rkey != key)
			}
		}
	}

	getHiddenDiseaseColors() {
		return this.hidden_disease_colors;
	}

	setHiddenDiseaseColors(hidden_disease_colors) {
		this.hidden_disease_colors = hidden_disease_colors;
	}

	addHiddenDiseaseColor(hidden_disease_color) {
		this.hidden_disease_colors.push(hidden_disease_color);
	}

	removeHiddenDiseaseColor(hidden_disease_color) {
		if (hidden_disease_color.disease_id) {
			this.hidden_disease_colors = this.hidden_disease_colors.filter(disease => disease.disease_id !== hidden_disease_color.disease_id);
		} else {
			this.hidden_disease_colors = this.hidden_disease_colors.filter(disease => disease.umls_id !== hidden_disease_color.umls_id);
		}
	}

	getAllDiseases() {
		return this.diseases;
	}

	getDiseases(key) {
		if (key in this.diseases) {
			return this.diseases[key];
		}
		return [];
	}

	setAllDiseases(diseases) {
		this.diseases = diseases;
	}

	setDiseases(key, diseases) {
		this.diseases[key] = diseases;
		this.profiles[key]['diseases'] = JSON.parse(JSON.stringify(diseases));
	}

	setDisease(key, disease, disease_id, umls_id) {
		// TODO
	}

	deleteDiseases(key) {
		if (key in this.diseases) delete this.diseases[key];
	}

	deleteDisease(key, disease_rkey) {
		if (key in this.diseases) {
			const diseases = this.diseases[key].filter((d) => d.rkey !== disease_rkey);
			this.diseases[key] = diseases;
		}
	}

	getGenes(key) {
		if (key in this.genes) {
			return this.genes[key];
		}
		return [];
	}

	setAllGenes(genes) {
		this.genes = genes;
	}

	setGenes(key, genes) {
		this.genes[key] = genes;
		this.profiles[key].genetic_testing = JSON.parse(JSON.stringify(genes));
	}

	setAllGenePanels(genePanels) {
		this.genePanels = genePanels;
	}

	setGenePanels(key, genePanels) {
		this.genePanels[key] = genePanels;
		this.profiles[key].gene_panels = JSON.parse(JSON.stringify(genePanels));
	}

	getGenePanels(key) {
		if(key in this.genePanels){
			return this.genePanels[key];
		}
		return []
	}
	getAllPartners() {
		return this.partners;
	}

	getPartner(key) {
		if (key in this.partners) {
			return this.partners[key];
		}
		return [];
	}

	setAllPartners(partners) {
		this.partners = partners;
	}

	setPartner(key, partner) {
		this.partners[key] = partner;
	}

	deletePartnersByKey(key) {
		if (key in this.partners) {
			delete this.partners[key];
		}
	}

	getSonDaughter(key) {
		if (key in this.sons_daughters) {
			return this.sons_daughters[key];
		}
		return [];
	}

	setAllSonsDaughters(sons_daughters) {
		this.sons_daughters = sons_daughters;
	}

	setSonDaughter(key, son_daughter) {
		this.sons_daughters[key] = son_daughter;
	}

	getUncleAunt(key) {
		if (key in this.uncles_aunts) {
			return this.uncles_aunts[key];
		}
		return [];
	}

	setAllUnclesAunts(uncles_aunts) {
		this.uncles_aunts = uncles_aunts;
	}

	setUncleAunt(key, uncle_aunt) {
		this.uncles_aunts[key] = uncle_aunt;
	}

	getSibling(key) {
		if (key in this.siblings) {
			return this.siblings[key];
		}
		return [];
	}

	setAllSiblings(siblings) {
		this.siblings = siblings;
	}

	setSibling(key, sibling) {
		this.siblings[key] = sibling;
	}

	setGene(key, gene, gene_id, umls_id) {
		// TODO
	}

	deleteGenePanel(key) {
		if(key in this.genePanels){
			delete this.genePanels[key];
		}
	}

	deleteGenes(key) {
		if (key in this.genes) delete this.genes[key];
	}

	deleteGene(key, gene_rkey) {
		if (key in this.genes) {
			const genes = this.genes[key].filter((g) => g.rkey !== gene_rkey);
			this.genes[key] = genes;
		}
	}

	getRiskCriteria() {
		return this.risk_criteria;
	}

	setRiskCriteria(risk_criteria) {
		this.risk_criteria = risk_criteria;
	}

	getRiskFactors() {
		return this.risk_factors;
	}

	setRiskFactors(risk_factors) {
		this.risk_factors = risk_factors;
	}

	getRiskResults() {
		return this.risk_results;
	}

	setRiskResults(risk_results) {
		this.risk_results = risk_results;
	}

	getRiskSettings() {
		return this.risk_settings;
	}

	setRiskSettings(risk_settings) {
		this.risk_settings = risk_settings;
	}

	getDocuments() {
		return this.documents;
	}

	setDocuments(documents) {
		this.documents = documents;
	}

	setDocument(key, document) {
		this.documents[key] = document;
	}

	deleteDocument(key) {
		if (key in this.documents) delete this.documents[key];
	}

	getPedigreeNotes() {
		return this.pedigree_notes;
	}

	setPedigreeNotes(pedigree_notes) {
		this.pedigree_notes = pedigree_notes;
	}

	getSavedNodePositions(){
		return this.saved_node_positions;
	}

	setSavedNodePositions(node_positions){
		this.saved_node_positions = node_positions
	}

	getProgenyArchiveData() {
		return this.progeny_archive_data;
	}

	setProgenyArchiveData(progeny_archive_data) {
		this.progeny_archive_data = progeny_archive_data;
	}

	getProgenyArchivePreferences() {
		return this.progeny_archive_preferences;
	}

	setProgenyArchivePreferences(progeny_archive_preferences) {
		this.progeny_archive_preferences = progeny_archive_preferences;
	}

	setMemberLinks(member_links){
		this.member_links = member_links;
	}

	getMemberLinks(){
		return this.member_links;
	}

	setConsanguineousConnectionActive(consanguineous_connection_active){
		this.consanguineous_connection_active = consanguineous_connection_active;
	}

	getConsanguineousConnectionActive(){
		return this.consanguineous_connection_active;
	}

	setReassignParentsConnectionActive(reassign_parents_connection_active){
		this.reassign_parents_connection_active = reassign_parents_connection_active;
	}

	getReassignParentsConnectionActive(){
		return this.reassign_parents_connection_active;
	}

	setDonorAssign(active, donor_record, donee_id){
		this.donor_assign = {
			active,
			donor_record,
			donee_id
		};
	}

	getDonorAssign(){
		return this.donor_assign;
	}

	setDonors(donors){
		this.donors = donors;
	}

	getDonors(){
		return this.donors;
	}

	setSiblingOrders(sibling_orders){
		this.sibling_orders = sibling_orders;
	}

	getSiblingOrders(){
		return this.sibling_orders;
	}

	setCollapsible(collapsible){
		this.collapsible = collapsible;
	}

	getCollapsible(){
		return this.collapsible;
	}

	setExpandedCollapsedGroups(expanded_collapsed_groups){
		this.expanded_collapsed_groups = expanded_collapsed_groups;
	}

	getExpandedCollapsedGroups(){
		return this.expanded_collapsed_groups;
	}

	setProbandRelationTree(proband_relation_tree){
		this.proband_relation_tree = proband_relation_tree;
	}

	getProbandRelationTree(){
		return this.proband_relation_tree;
	}
}
